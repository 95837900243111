import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { heroImagesState } from './reducer';

export const getHeroImages = (state: RootState) => state.heroImages;

export const getItems = createSelector(getHeroImages, (state: heroImagesState) => state.items);
export const getIsFetching = createSelector(
  getHeroImages,
  (state: heroImagesState) => state.isFetching,
);
