import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { introductionBannerState } from './reducer';

export const getErrors = (state: RootState) => state.generalContents.errors;

export const getIntroductionBanner = (state: RootState) => state.introductionBanner;

export const getItem = createSelector(
  getIntroductionBanner,
  (state: introductionBannerState) => state.item,
);

export const getIsFetching = createSelector(
  getIntroductionBanner,
  (state: introductionBannerState) => state.isFetching,
);
