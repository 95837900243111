import React from 'react';
import { useSelector } from 'react-redux';

import ScrollableSection from '@travel/traveler-core/components/ScrollableSection';
import useDeviceType, {
  DESKTOP_SCREEN,
  MOBILE_SCREEN,
} from '@travel/traveler-core/hooks/useDeviceType';
import { cx } from '@travel/utils';

import Media from 'components/Media';
import Swiper from 'components/Swiper';
import WithSkeletonLoading from 'components/WithSkeletonLoading';

import {
  getIsFetching as getIsGeneralContentsFetching,
  getItems as getGeneralContentsItems,
} from 'store/generalContents/selectors';
import {
  getIsFetching as getIsIntroductionBannerFetching,
  getItem as getIntroductionBanner,
} from 'store/introductionBanner/selectors';

import { GeneralContents, GeneralContentsItem } from 'GeneralContents-Types';
import { IntroductionBanner } from 'IntroductionBanner-Types';

import styles from './valueProposition.module.scss';

const VALUE_PROPOSITION_HEADER_ID = 'value-proposition-header-id';

function ItemCard(props: { item: GeneralContentsItem; isSP: boolean }) {
  const { item, isSP } = props;

  return (
    <div
      className={isSP ? styles.itemContent : styles.item}
      data-testid="valueProposition-item-card"
    >
      <Media
        wrapperClassName={styles.imageStyle}
        placeholderClassName={styles.imageStyle}
        className={styles.media}
        media={{ url: item.img_src, alt: '' }}
        sizes="10vw"
        isDisableLazyload={true}
      />
      <div className={styles.textStyle}>
        {item.title && <span className={styles.title}>{item.title}</span>}
        <p className={styles.descriptions}>{item.description}</p>
      </div>
    </div>
  );
}

function GeneralContentCard(props: {
  generalContents: GeneralContents;
  isSP: boolean;
  id?: string;
}) {
  const { generalContents, isSP, id } = props;

  return (
    <>
      <h3 id={id} className={styles.slideHeader}>
        {generalContents.introductiontext_title}
      </h3>
      <div
        className={styles.generalContentWrapper}
        data-testid="valueProposition-introductiontext-items-wrapper"
      >
        {generalContents?.value_prop?.map((item, index) => (
          <ItemCard key={`introductiontext-${index}`} item={item} isSP={isSP} />
        ))}
      </div>
    </>
  );
}

function BannerCard(props: { introductionBanner: IntroductionBanner; isSP: boolean }) {
  const { introductionBanner, isSP } = props;

  return (
    <>
      <p className={styles.slideHeader}>{introductionBanner.introductionbanner_title}</p>
      <div
        className={styles.bannerContentWrapper}
        data-testid="valueProposition-introductionbanner-items-wrapper"
      >
        {introductionBanner?.quality_badge?.map((item, index) => (
          <ItemCard key={`introductionbanner-${index}`} item={item} isSP={isSP} />
        ))}
      </div>
    </>
  );
}

function ValueProposition() {
  const isPC = useDeviceType() === DESKTOP_SCREEN;
  const isSP = useDeviceType() === MOBILE_SCREEN;
  const generalContents = useSelector(getGeneralContentsItems);
  const introductionBanner = useSelector(getIntroductionBanner);
  const isGeneralContentsFetching = useSelector(getIsGeneralContentsFetching);
  const isIntroductionBannerFetching = useSelector(getIsIntroductionBannerFetching);

  if ((!generalContents || !generalContents.value_prop?.length) && !isGeneralContentsFetching) {
    return null;
  }

  if (
    (!introductionBanner || !introductionBanner.quality_badge?.length) &&
    !isIntroductionBannerFetching
  ) {
    return null;
  }

  return (
    <section
      aria-labelledby={VALUE_PROPOSITION_HEADER_ID}
      className={styles.valuePropositionContainer}
      data-testid="valueProposition-wrapper"
    >
      <WithSkeletonLoading
        className={cx(styles.generalContentsContainer, styles.loaderContainer)}
        skeletonImageClassName={styles.loaderImage}
        isLoading={isGeneralContentsFetching || isIntroductionBannerFetching}
        count={isSP ? 2 : 4}
        row={1}
        direction={'row'}
        customStyle="textWithImageColumn"
        data-testid="valueProposition-loader"
      >
        {isPC ? (
          <Swiper
            className={styles.swiperContent}
            hasCircleColor
            hasClickableIndicator
            showNavButtons
          >
            <GeneralContentCard
              id={VALUE_PROPOSITION_HEADER_ID}
              generalContents={generalContents}
              isSP={isSP}
            />
            <BannerCard introductionBanner={introductionBanner} isSP={isSP} />
          </Swiper>
        ) : (
          <div className={styles.spContent}>
            <h3 id={VALUE_PROPOSITION_HEADER_ID} className={styles.mainTitle}>
              {generalContents.introductiontext_title}
            </h3>
            <ScrollableSection
              className={styles.scrollableContent}
              childClassName={styles.scrollableItem}
            >
              {generalContents?.value_prop?.map((item, index) => (
                <div
                  className={cx(styles.generalContentWrapperSP, styles.item)}
                  data-testid={`valueProposition-general-content-${index}`}
                  key={`introductiontext-${index}`}
                >
                  <ItemCard item={item} isSP={isSP} />
                </div>
              ))}
              {introductionBanner?.quality_badge?.map((item, index) => (
                <div
                  className={cx(styles.bannerContentWrapperSP, styles.item)}
                  data-testid={`valueProposition-banner-${index}`}
                  key={`introductionbanner-${index}`}
                >
                  <ItemCard item={item} isSP={isSP} />
                </div>
              ))}
            </ScrollableSection>
          </div>
        )}
      </WithSkeletonLoading>
    </section>
  );
}

export default ValueProposition;
