import React from 'react';
import { useSelector } from 'react-redux';

import { rental_car_banner_pc, rental_car_banner_sp } from 'media';

import { getLanguage, getMarket } from '@travel/i18n';
import cx from '@travel/icons/_utils/classnames';
import { ArrowRightThick } from '@travel/icons/ui';
import {
  DESKTOP_SCREEN,
  useDeviceType,
  useDialogHandler,
  useTranslation,
} from '@travel/traveler-core/hooks';
import { FlatButton } from '@travel/ui';

import { Translate } from 'core/translate';

import DataLayer from '../DataLayer';

import styles from './rentalCarBanner.module.scss';

export type RentalCarBannerProps = {
  className?: string;
  contentClassName?: string;
  bannerImgClassName?: string;
  bannerTitleClassName?: string;
  bannerSubtitleClassName?: string;
  hasButton?: boolean;
  pageName: string;
  pageType: string;
  siteSection: string;
};

function RentalCarBanner(props: RentalCarBannerProps) {
  const isPC = useDeviceType() === DESKTOP_SCREEN;
  const data = {
    marketcode: useSelector(getMarket)?.marketCode.toLowerCase(),
    languagesetting: useSelector(getLanguage).toLowerCase(),
  };
  const rentalCarLink = useTranslation({
    id: 'Traveler_Common.Rental_Car_URL',
    data: data,
  });
  const {
    className,
    contentClassName,
    bannerImgClassName,
    bannerTitleClassName,
    bannerSubtitleClassName,
    hasButton,
    pageName,
    pageType,
    siteSection,
  } = props;
  const {
    isOpen: isRentalCarBannerClicked,
    onOpen: setRentalCarBannerClicked,
    onClose: setRentalCarBannerClose,
  } = useDialogHandler(false);

  const handleRentalCarDataLayer = () => {
    setRentalCarBannerClicked();
    setTimeout(() => setRentalCarBannerClose(), 1000);
  };
  return (
    <section data-testid="rentalcar-banner-wrapper">
      <a
        className={cx(styles.rentalCarBanner, className)}
        data-testid="rentalcar-banner"
        href={rentalCarLink}
        target="_blank"
        rel="noreferrer noopener"
        onClick={handleRentalCarDataLayer}
      >
        <div className={cx(styles.rentalCarImg, bannerImgClassName)}>
          <img src={isPC ? rental_car_banner_pc : rental_car_banner_sp} alt="" loading={`lazy`} />
        </div>
        <div className={cx(styles.rentalCarContent, contentClassName)}>
          <div className={cx(styles.title, bannerTitleClassName)}>
            <Translate id="Top.Rental_Car_Banner.Title" />
          </div>
          <div className={cx(styles.subtitle, bannerSubtitleClassName)}>
            <Translate id="Top.Rental_Car_Banner.Subtitle" />
          </div>
        </div>
        {isPC && hasButton && (
          <div>
            <FlatButton
              className={styles.exploreRentalButton}
              data-testid="rentalcar-banner-button"
            >
              <Translate id="Top.Rental_Car_Banner.Explore_Rentals" />
            </FlatButton>
          </div>
        )}
        {!isPC && <ArrowRightThick size={16} color="cilantro" />}
      </a>
      {isRentalCarBannerClicked && (
        <DataLayer pageName={pageName} pageType={pageType} siteSection={siteSection} />
      )}
    </section>
  );
}

export default RentalCarBanner;
