import React from 'react';

import DateInput, { DateRef, Props } from './';

const DateInputWithRef = React.memo(
  React.forwardRef((props: Props, ref: React.Ref<DateRef>) => (
    <DateInput {...props} innerRef={ref} />
  )),
);

export default DateInputWithRef;
