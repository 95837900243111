import React from 'react';
import { useSelector } from 'react-redux';

import { Discount } from '@travel/icons/ui';
import { DESKTOP_SCREEN, useDeviceType } from '@travel/traveler-core/hooks';
import { FlatButton } from '@travel/ui';

import { getRouter } from 'store/__router/selectors';
import { getMember } from 'store/member/selectors';

import { Translate } from 'core/translate';
import useLogin from 'hooks/useLogin';

import styles from './loginPrompt.module.scss';

function LoginPrompt() {
  const isPC = useDeviceType() === DESKTOP_SCREEN;
  const router = useSelector(getRouter);
  const {
    baseUrl,
    location: { location },
  } = router;
  const callback = location.pathname + location.search;
  const { isMember: isLogin } = useSelector(getMember);
  const { onClickLogin: login } = useLogin();
  const onClickLogin = () => {
    login(baseUrl + callback);
  };
  if (isLogin) {
    return null;
  }

  return (
    <section className={styles.wrapper} data-testid="loginPrompt-wrapper">
      <div className={styles.loginPrompt} data-testid="loginPrompt">
        <div className={styles.loginPromptIcon}>
          <Discount size={isPC ? 48 : 36} />
        </div>
        <div className={styles.loginPromptContent}>
          <h2 className={styles.header}>
            <Translate id="Traveler_Common.Sign_In_Prompt.Title" />
          </h2>
          <div className={styles.body}>
            <Translate id="Traveler_Common.Sign_In_Prompt.Content" />
          </div>
        </div>
        <FlatButton
          onClick={onClickLogin}
          className={styles.loginPromptButton}
          data-testid="loginPrompt-button"
        >
          <Translate id="Traveler_Common.Sign_In_Prompt.Button" />
        </FlatButton>
      </div>
    </section>
  );
}

export default LoginPrompt;
