import dayjs from 'dayjs';
import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getLanguage } from '@travel/i18n';
import RanDataFormInputs from '@travel/traveler-core/components/RanDataFormInputs';
import { DESKTOP_SCREEN, useDeviceType } from '@travel/traveler-core/hooks';
import { useDatesChange } from '@travel/traveler-core/hooks/useDatesChange';
import { getRanData } from '@travel/traveler-core/store/ranStore/selectors';
import { FlatButton } from '@travel/ui';

import { AmountInputRef } from 'components/AmountInput';
import AmountInputWithRef from 'components/AmountInput/AmountInputWithRef';
import { DateRef } from 'components/DateInput';
import DateInputWithRef from 'components/DateInput/DateInputWithRef';
import ErrorMessage from 'components/ErrorMessage';
import Form, { FormSubmit } from 'components/Form';
import PlaceInput from 'components/PlaceInput';
import SalesPromotionInput, { SalesPromotionFormQuery } from 'components/SalesPromotionInput';

import { Translate } from 'core/translate';
import usePlaceRedirect from 'hooks/usePlaceRedirect';
import { SuggestionsItem } from 'Suggestions-Types';
import { savedSearchStore } from 'utils/savedSearch';

import styles from './searchForm.module.scss';

type Props = SalesPromotionFormQuery;

function SearchForm(props: Props) {
  const placeRedirect = usePlaceRedirect();
  const language = useSelector(getLanguage);
  const ranData = useSelector(getRanData);
  const isPC = useDeviceType() === DESKTOP_SCREEN;

  const [isError, setIsError] = useState(false);
  const [place, setPlace] = useState<SuggestionsItem | null>(null);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const { isDatesSet, onDatesSet } = useDatesChange(false);
  const [isAutoOpenDatePicker, setIsAutoOpenDatePicker] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const dateRef = useRef<DateRef>(null);
  const amountInputRef = useRef<AmountInputRef>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const onSubmit = ({ query, queryObj }: FormSubmit) => {
    if (!place?.pathId) {
      setIsError(true);
      return false;
    }

    savedSearchStore(language, place, queryObj);

    setIsSubmitButtonLoading(true);
    placeRedirect(place, query);
  };

  const onChangePlace = (place: SuggestionsItem) => {
    setIsError(false);
    setPlace(place);
    if (place.isSavedSearch && place?.query) {
      // setDate
      const startDate = place.query.startDate && place.query.startDate.toString();
      const endDate = place.query.endDate && place.query.endDate.toString();
      // the dates needs to be set for the childrenAges to be set
      let shouldSetAmount = true;
      if (startDate && endDate) {
        if (dayjs(startDate).isSameOrBefore(dayjs().subtract(1, 'day'))) {
          shouldSetAmount = false;
          dateRef.current?.resetDate();
          setIsAutoOpenDatePicker(true);
          dateRef.current?.focus();
          setStartDate('');
          setEndDate('');
          onDatesSet('', '');
        } else {
          setStartDate(startDate);
          setEndDate(endDate);
          onDatesSet(startDate, endDate);
        }
      }
      // set rooms/guests/children amount
      if (shouldSetAmount) {
        amountInputRef.current?.setAmount({
          rooms: place.query.noOfUnits ? Number(place.query.noOfUnits) : undefined,
          adults: place.query.adults,
          childrenAges: place.query.childrenAges,
        });
      }
      if (startDate && endDate && shouldSetAmount) {
        buttonRef.current?.focus();
      }
    }
  };

  const onSelectPlace = (isSavedSearch?: boolean, hasDate?: boolean) => {
    if (isSavedSearch && !hasDate && isDatesSet) {
      dateRef.current?.resetDate();
      setStartDate('');
      setEndDate('');
      onDatesSet('', '');
    }
    if (!isSavedSearch && !isDatesSet && !hasDate) {
      setIsAutoOpenDatePicker(true);
      dateRef.current?.focus();
    }
  };

  const onDeletePlace = useCallback(() => setPlace(null), [setPlace]);

  const errorMessage = (
    <Translate
      id="Traveler_Input_Error.Global_Search.Destination_Search.No_Input"
      className={styles.errorMessage}
    />
  );

  return (
    <Form data-testid="searchForm-form-wrapper" className={styles.searchForm} onSubmit={onSubmit}>
      <h1 className={styles.titleWrapper}>
        <Translate id="Top.Tagline" className={styles.topLabel} />
      </h1>

      <div className={styles.fields}>
        <PlaceInput
          className={styles.placeInputWrapper}
          onPage="top"
          placeId={place?.pathId}
          onChangePlace={onChangePlace}
          onSelectPlace={onSelectPlace}
          onDeletePlace={onDeletePlace}
          hasError={isError}
          errorMessage={errorMessage}
          errorMessageClassName={styles.errorMessageWrapper}
          hasAnimation={true}
        />
        <DateInputWithRef
          ref={dateRef}
          onPage="top"
          onDateChanged={onDatesSet}
          startDate={startDate}
          endDate={endDate}
          isAutoOpenDatePicker={isAutoOpenDatePicker}
          setIsAutoOpenDatePicker={setIsAutoOpenDatePicker}
          hasAnimationDialog={true}
        />
        <AmountInputWithRef
          ref={amountInputRef}
          onPage="top"
          isDatesSet={isDatesSet}
          className={styles.amountInput}
          popupWrapperClassName={styles.amountInputPopup}
          hasAnimationDialog={true}
        />

        <SalesPromotionInput
          salesPromotionId={props.salesPromotionId}
          promotionCode={props.promotionCode}
        />

        <RanDataFormInputs ranData={ranData} />

        <FlatButton
          type="submit"
          className={styles.searchButton}
          isLoading={isSubmitButtonLoading}
          data-testid="searchForm-flatButton-button"
          innerRef={buttonRef}
        >
          <Translate id="Top.Search_Button" />
        </FlatButton>
      </div>
      {isPC && isError && (
        <ErrorMessage iconSize={16} message={errorMessage} className={styles.errorMessageWrapper} />
      )}
    </Form>
  );
}

export default SearchForm;
