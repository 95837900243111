import loadable from '@loadable/component';
import React from 'react';
import { useSelector } from 'react-redux';

import { DESKTOP_SCREEN, useDeviceType } from '@travel/traveler-core/hooks';

import DataLayer from 'components/DataLayer';
import EmailSubscription from 'components/EmailSubscription';
import useSubscriptionScreenType from 'components/EmailSubscription/useSubscriptionScreenType';
import HeroImage from 'components/HeroImage';
import LoginPrompt from 'components/LoginPrompt';
import PromotionBanners from 'components/PromotionBanners';
import RentalCarBanner from 'components/RentalCarBanner';

import { getIsFetching as getIsGeneralContentsFetching } from 'store/generalContents/selectors';
import {
  getIsFetching as getIsHeroImagesFetching,
  getItems as getHeroImageItems,
} from 'store/heroImages/selectors';
import { getIsFetching as getIsBannersFetching } from 'store/promotionBanners/selectors';
import { getIsFetching as getIsSpecialArticlesFetching } from 'store/specialFeatureArticles/selectors';
import { getIsFetching as getIsSubscriptionFetching } from 'store/subscription/selectors';

import { SUBSCRIPTION_LOCATION } from 'constants/subscriptionLocation';
import { Translate } from 'core/translate';
import { SearchFormQuery } from 'ProviderList-Types';

import { RAT_PAGE_TYPE_OBJ } from '../../constants/ratAnalytics';
import usePageLoaderOnInitial from '../../hooks/usePageLoaderOnInitial';
// import AppDownloadBanner from './components/AppDownloadBanner';
import Recommendations from './components/Recommendations';
import SearchForm from './components/SearchForm';
import SpecialFeatureArticles from './components/SpecialFeatureArticles';
import ValueProposition from './components/ValueProposition';

import styles from './topPage.module.scss';

const AppDownloadBanner = loadable(
  () =>
    import(
      /* webpackMode: "lazy" */
      /* webpackFetchPriority: "low" */
      /* webpackChunkName: "component-AppDownloadBanner" */
      './components/AppDownloadBanner'
    ),
  { ssr: false },
);

type Props = {
  query: SearchFormQuery;
};

function TopPage(props: Props) {
  const pageName = 'Top';

  // page loader
  const isHeroImagesFetching = useSelector(getIsHeroImagesFetching);
  const isGeneralContentsFetching = useSelector(getIsGeneralContentsFetching);
  const isSpecialArticlesFetching = useSelector(getIsSpecialArticlesFetching);
  const isBannersFetching = useSelector(getIsBannersFetching);
  const isSubscriptionFetching = useSelector(getIsSubscriptionFetching);
  usePageLoaderOnInitial(
    pageName,
    isGeneralContentsFetching ||
      isSpecialArticlesFetching ||
      isBannersFetching ||
      isHeroImagesFetching ||
      isSubscriptionFetching,
  );
  // hero images
  const heroImages = useSelector(getHeroImageItems);

  const screenType = useSubscriptionScreenType();
  const deviceType = useDeviceType();
  const isPC = deviceType === DESKTOP_SCREEN;
  const { pageType, siteSection } = RAT_PAGE_TYPE_OBJ.top;
  const parentNameRentalCar = RAT_PAGE_TYPE_OBJ.topRentalCar.parentName;
  return (
    <div data-testid="topPage-page-wrapper">
      <HeroImage
        page={pageName}
        images={heroImages.map(image => ({ url: image.img_src, alt: image.title }))}
        isTaglineFetching={isHeroImagesFetching}
      />

      <div className={styles.contentWrapper}>
        <SearchForm
          salesPromotionId={props.query.salesPromotionId}
          promotionCode={props.query.promotionCode}
        />
        <ValueProposition />
        <PromotionBanners onPage="top" />
        <LoginPrompt />
        <SpecialFeatureArticles title={<Translate id="Top.Article_banner.Title" />} />
        <Recommendations title={<Translate id="Top.Recommended_Destination.Title" />} page="Top" />

        <div className={styles.appBannerAndEmailSubsWrapper}>
          {isPC && <AppDownloadBanner />}
          {screenType && (
            <EmailSubscription location={SUBSCRIPTION_LOCATION.TOP} screenType={screenType} />
          )}
        </div>

        <RentalCarBanner
          className={styles.rentalCarBanner}
          hasButton={true}
          pageName={parentNameRentalCar}
          pageType={pageType}
          siteSection={siteSection}
        />
      </div>

      {/* <MobileAppBanner shouldShowBackGround={true} />
      <SnsLinks /> */}
      <DataLayer pageName="top:search_form" pageType="top" siteSection="top" />
    </div>
  );
}

export default TopPage;
