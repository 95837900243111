import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { GeneralContentsState } from './reducer';

export const getErrors = (state: RootState) => state.generalContents.errors;

export const getGeneralContents = (state: RootState) => state.generalContents;

export const getItems = createSelector(
  getGeneralContents,
  (state: GeneralContentsState) => state.items,
);

export const getIsFetching = createSelector(
  getGeneralContents,
  (state: GeneralContentsState) => state.isFetching,
);
